export const ROUTES = {
  card: {
    path: '/:dealType/:offerType/:cianId(\\d+)',
  },
  serp: {
    path: ['/snyat-*', '/kupit-*', '/cat.php', '/:subdomain/snyat-*', '/:subdomain/kupit-*', '/:subdomain/cat.php'],
  },
  resultsForMap: {
    path: ['/public/v1/get-results-for-map', '/mobile-search-frontend/v1/get-results-for-map'],
  },
};
